import { Step } from '@interfaces/tour';
import { Typography } from '@vibTheme/components/_base/Typography';

export const productCreationTourSteps: Step[] = [
  {
    id: '0',
    name: 'product-create',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Clique no botão Adicionar para criar sua comunidade.
        </Typography>
      </div>
    ),
    url: '/products',
  },
  {
    id: '1',
    name: 'bind-telegram',
    popoverContent: (
      <div>
        <Typography className="text-black">Associe seu Telegram.</Typography>
      </div>
    ),
    url: '/products/create',
    nextCallback: () => {
      throw 'Você precisa associar sua conta no Telegram antes de prosseguir!';
    },
  },
  {
    id: '2',
    name: 'product-type-chat-type',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Selecione o tipo da comunidade: Supergrupo ou Comunidade
        </Typography>
      </div>
    ),
    url: '/products/create',
    previousCallback: clickPrevious,
  },
  {
    id: '3',
    name: 'product-type-chat-type-helper',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Se estiver em dúvida entre Supergrupo ou Comunidade, saiba mais aqui
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '4',
    name: 'product-type-category',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Selecione a categoria que mais se encaixa com o seu conteúdo
        </Typography>
      </div>
    ),
    url: '/products/create',
    nextCallback: clickNext,
  },
  {
    id: '5',
    name: 'product-info-name',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Preencha o nome da sua comunidade do Telegram.
        </Typography>
      </div>
    ),
    url: '/products/create',
    previousCallback: clickPrevious,
  },
  {
    id: '6',
    name: 'product-info-token',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Gere o token através do bot "BotFather" do Telegram.
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '7',
    name: 'product-info-token-helper',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Caso não saiba gerar o token, confira um passo a passo aqui.
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '8',
    name: 'product-info-support-contact',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Informe um contato para suporte aos seus assinantes. Pode ser o @ do
          seu Telegram, número de Whatsapp ou seu e-mail.
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '9',
    name: 'product-info-seller-name',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Escolha um nome para o vendedor que será exibido aos assinantes. Pode
          ser seu nome artístico, nome fantasia ou razão social.
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '10',
    name: 'product-info-payment-methods',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Escolha os métodos de pagamento que oferecerá aos assinantes e
          desative os que não quiser.
        </Typography>
      </div>
    ),
    url: '/products/create',
    nextCallback: clickNext,
  },
  {
    id: '11',
    name: 'product-payment-offer-name',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Preencha o nome a oferta que será exibida para o comprador.
          <br />
          Ex.: "Assinatura Mensal"
        </Typography>
      </div>
    ),
    url: '/products/create',
    previousCallback: clickPrevious,
  },
  {
    id: '12',
    name: 'product-payment-offer-periodicity',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Selecione o período que o assinante terá acesso
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '13',
    name: 'product-payment-offer-price',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Digite o valor que será cobrado pela assinatura durante o período
          selecionado
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
  {
    id: '14',
    name: 'product-payment-pix',
    popoverContent: (
      <div>
        <Typography className="text-black">
          Informe a chave Pix para transferir os ganhos da assinatura da
          comunidade. A chave de ser do mesmo{' '}
          <strong>titular do documento</strong> cadastrado.
        </Typography>
      </div>
    ),
    url: '/products/create',
  },
];

async function clickNext() {
  const nextButton = document.getElementById(
    'product-creation-next',
  ) as HTMLButtonElement;
  if (nextButton.disabled)
    throw 'Verifique se todos os campos estão preenchidos corretamente!';

  nextButton?.click();
}

async function clickPrevious() {
  const previousButton = document.getElementById('product-creation-previous');
  previousButton?.click();
}
