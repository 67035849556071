import { PropsWithChildren } from 'react';
import { AppBar } from '@mui/material';
import { Link } from '@vibTheme/components/_base/Link';
import VibxLogoWhite from '@public/assets/vibx-logo-white.svg';

export default function LayoutAppbarOnly({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col min-h-screen">
      <AppBar className="fixed justify-center h-navbarHeight px-4">
        <Link href="/" className="flex items-center w-fit">
          <VibxLogoWhite alt="Vibx Logo" height="40" />
        </Link>
      </AppBar>

      <main
        className={`flex flex-col items-center justify-center flex-1 p-main mt-navbarHeight`}
      >
        {children}
      </main>
    </div>
  );
}
