import { CircularProgress, CircularProgressProps } from '@mui/material';
import { twMerge } from 'tailwind-merge';

export type VibSpinnerProps = CircularProgressProps & {
  containerClassName?: string;
};

export const VibSpinner = (props: VibSpinnerProps) => {
  const { containerClassName, ...restProps } = props;
  const containerBaseStyles = 'flex items-center justify-center h-full';

  return (
    <div className={twMerge(containerBaseStyles, containerClassName)}>
      <CircularProgress {...restProps} />
    </div>
  );
};
