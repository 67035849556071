import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import Image from 'next/image';
import { IconButton } from '@mui/material';
import { vibxConstants } from '@utils/constants';

interface InstagramBannerProps {
  setIsBannerClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InstagramBanner = ({
  setIsBannerClosed,
}: InstagramBannerProps) => {
  const handleClose = () => {
    localStorage.setItem('bannerClosed', 'true');
    setIsBannerClosed(true);
  };
  return (
    <div className="absolute bottom-28 z-0 w-calc-100-2 mx-md p-sm rounded-md bg-[#5111AD]">
      <div className="relative">
        <IconButton
          className="absolute top-0 right-0 p-0"
          onClick={handleClose}
        >
          <CloseIcon className="text-white text-xs" />
        </IconButton>
        <Image
          src="/assets/girl-holding-phone.svg"
          alt="Menina segurando um telefone"
          width={70}
          height={70}
          className="absolute bottom-0"
        />
        <div className="ml-20">
          <p className="text-[10px] text-white">Esteja por dentro!</p>
          <a
            href={vibxConstants.INSTAGRAM_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-1 items-center cursor-pointer no-underline"
          >
            <InstagramIcon className="text-white text-base" />
            <p className="text-[11px] text-white font-bold">
              Siga-nos no Instagram!
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};
